/* eslint-disable vue/no-duplicate-attributes */
<template>
	<div>
		<template v-if="loading">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<section class="col-md-12">
				<h4>More Audit Details</h4>
				<div class="bg-white p-5">
					<form>
						<div class="settings__field">
							<div class="form-row">
								<div class="form-group col-md-12">
									<div class="settings__value">
										<label for="fname">Investor Name</label>
										<p id="fullName">{{ fullName }}</p>
									</div>
								</div>
								<div class="form-group col-md-6">
									<div class="settings__value capital">
										<label for="name">Action</label>
										<p id="action">{{ itemData.action | removeUnderscore }}</p>
									</div>
								</div>
								<div class="form-group col-md-6">
									<div class="settings__value">
										<label for="title">Title</label>
										<p id="title">{{ itemData.title }}</p>
									</div>
								</div>
								<div class="form-group col-md-6">
									<div class="settings__value">
										<label for="type">Type</label>
										<p id="type">{{ itemData.type }}</p>
									</div>
								</div>
								<div class="form-group col-md-6">
									<div class="settings__value">
										<label for="model">Model</label>
										<p id="model">{{ itemData.model }}</p>
									</div>
								</div>
								<div class="form-group col-md-6">
									<div class="settings__value">
										<label for="description">Description</label>
										<p id="description">{{ itemData.description }}</p>
									</div>
								</div>
								<div class="form-group col-md-6">
									<div class="settings__value">
										<label for="date">Date</label>
										<p id="date">{{ itemData.created_at | date }}</p>
									</div>
								</div>
								<div class="form-group col-md-12 mt-4">
									<div class="settings__value">
										<label for="data">More Data</label>
									</div>
									<table class="table-auto w-100">
										<tbody>
											<tr v-for="(data, key) in keyValues" class="bg-gray-100 capital" :key="key">
												<template>
													<template v-if="data.key === 'user'">
														<td class="border px-4 py-2">
															{{ data.key }}
														</td>
														<td class="border px-4 py-2">
															{{ data.value.firstname }}
															{{ data.value.lastname }}
														</td>
													</template>
													<template v-else>
														<td class="border px-4 py-2">
															{{ data.key }}
														</td>
														<td class="border px-4 py-2">
															{{ data.value }}
														</td>
													</template>
												</template>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</form>
				</div>
			</section>
		</template>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';
import Loader from '../../components/Loader';

export default {
	name: 'AuditDetail',
	mixins: [form],
	data() {
		return {
			itemData: {},
			errors: {},
			loading: true
		};
	},
	created() {
		this.fetchAuditDetail(this.$route.params.id);
	},
	watch: {
		getStatus(value) {
			if (value === 'success') {
				this.itemData = this.getAuditDetail;
				this.loading = false;
			}
		}
	},
	computed: {
		...mapGetters(['getStatus', 'getAuditDetail', 'getErrorLog']),

		status() {
			return [
				this.itemData.status === 'ACTIVE' ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger'
			];
		},
		fullName() {
			return `${this.itemData.user.firstname} ${this.itemData.user.lastname}`;
		},
		moreData() {
			return JSON.parse(this.itemData.data);
		},
		keyValues() {
			const newArrayData = [];

			Object.keys(this.moreData).forEach((key) => {
				newArrayData.push({ key, value: this.moreData[key] });
			});

			return newArrayData
				.filter((data) => data.value)
				.map((data) => {
					// eslint-disable-next-line no-param-reassign
					data.key = data.key.replace(/_/g, ' ');
					return data;
				});
		}
	},
	methods: {
		...mapActions(['fetchAuditDetail'])
	},
	components: {
		Loader
	},
	filters: {
		date(val) {
			return moment(val).format('LLLL');
		},
		removeUnderscore(val) {
			return val.replace(/_/g, ' ');
		}
	}
};
</script>

<style lang="scss" scoped>
.activity__item {
	display: grid;
	grid-template-columns: 120px 1fr;
	grid-gap: 25px;
	margin-bottom: 22px;
}
.settings {
	max-width: 90%;
	&__field {
		margin-top: 30px;
	}
	&__value {
		padding: 0;
		background: #fff;
		input {
			background: #fafafa;
			border: none;
			font-size: 14px;
			height: 36px;
		}
		label {
			font-weight: bold;
			margin-bottom: 0;
		}
	}
}

.avatar {
	&__input {
		display: none;
	}
	&__label {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		margin: 0;
		&:hover {
			cursor: pointer;
		}
	}
	&__loader {
		position: absolute;
		top: 7px;
		right: -45px;
	}
}
h3 {
	padding-left: 6px;
}
.card {
	border-style: none;
}
.doc {
	&__wrapper {
		.text {
			margin-bottom: 15px;
		}
		margin-bottom: 50px;
	}
	&__img {
		width: 215px;
		height: 150px;
		object-fit: fill;
		object-position: center;
		//border: 1px solid $grey-light;
		border-radius: 4px;
		&--lg {
			width: 100%;
			height: auto;
		}
	}
}
.btn__wrapper {
	margin-top: 20px;
	.btn {
		margin: 4px;
		padding: 4px;
	}
}
.bold {
	font-weight: bold;
	font-size: 15px;
	margin-right: 5px;
}

.cards {
	padding-top: 2em;
	margin-right: 3em;
	&__row {
		display: flex;
		justify-content: space-between;
	}
	&__brand {
		font-weight: bold;
		padding-top: 1rem;
	}
	&__item {
		font-size: 1.5em;
		background: #f1f1f1;
		border: 1px solid #cccccc;
		border-radius: 6px;
		padding: 0.875em 1em;
		max-width: 350px;
		margin-bottom: 2.25rem;
		&:hover {
			.btn-sm {
				visibility: visible;
			}
		}
	}
}
.pad {
	padding: 10px;
}

.capital {
	text-transform: capitalize;
}
</style>
